import NProgress from 'nprogress'; // progress bar
import router, { asyncRoutes } from './index';
import store from '../store';
import authRouter from './modules/auth';
import 'nprogress/nprogress.css'; // progress bar style
import { _ } from 'core-js';
import API from '@/api/API.js';

NProgress.configure({ showSpinner: false }); // NProgress Configuration

/**
 * Generate white list
 */
const whiteList = (['/landing', '/land']
  .concat(Array.from(authRouter, (route) => route.path))
  .concat(Array.from(authRouter, (route) => route.alias)))
  .filter((route) => route); // remove undefined element
console.log('[router.whiteList]', whiteList);

/**
 * Check user has permission for this routes.
 * 'admin' permission passed directly.
 * @param {Array} roles
 * @param {Array} permissionRoles
 */
// eslint-disable-next-line no-unused-vars
function hasPermission(roles, permissionRoles) {
  try {
    if(roles.includes('any')) return true
    return permissionRoles.some(({name}) => roles.includes(name))
  } catch (e) {
    return false
  }
}

async function getUserAuthorities(to,next) {
  const listResponse = await API.listUserRoles('')
      if (!listResponse || listResponse.error) {
          //error getting data
          console.log(`${listResponse.error}`)
          //401 or 500
          next('/401')
      } else {
          var flatten = []
          try {
            let authoritiesArrayList = listResponse.userRoles.map(({authorities}) => ({authorities}))
            let authoritiesList = [].concat.apply([], authoritiesArrayList.map(({authorities}) => {
              if(authorities) {
                return (authorities.map((name) => ([name])))
              } else {
                return []
              }
            }))

            flatten = [].concat.apply([], authoritiesList)
            store.commit('SET_AUTHORITIES', flatten);
          } catch(e) {
            console.log(e)
          }
          store.commit('SET_ROLES', listResponse.userRoles);
          store.commit('SET_ROUTES', asyncRoutes);
          if(to.path === '/401' || to.path === '/') {
            next()
          }else if (hasPermission(to.meta.roles, flatten)) {
            next();
          } else {
            next('/401')
          }
      }
      NProgress.done();
}

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  // store.commit('SET_ROUTES', asyncRoutes);
  // next();
  if (router.app.$keycloak.authenticated) {
    console.log(router.app.$keycloak.token)
    const profile = await router.app.$keycloak.loadUserProfile();
    console.log(profile);
    store.commit('SET_USER_INFO', {
      user: profile.username,
      name: `${profile.firstName} ${profile.lastName}`,
    });
    if(to.path === '/401') {
      next()
    } else {
      getUserAuthorities(to,next)
    }
  } else {
    const loginUrl = router.app.$keycloak.createLoginUrl({
      redirectUri: to.path,
    });
    window.location.replace(loginUrl);
    NProgress.done();
  }
});

// router.beforeEach(async (to, from, next) => {
//   NProgress.start();
//   if (router.app.$keycloak.authenticated) {
//     const profile = await router.app.$keycloak.loadUserProfile();
//     const attributes = profile.attributes ? JSON.stringify(profile.attributes) : ''
//     store.commit('SET_USER_INFO', {
//       user: profile.username,
//       name: `${profile.firstName ?? ''} ${profile.lastName ?? ''}`,
//       attributes: `${attributes}`
//     });
//     store.commit('SET_ROUTES', asyncRoutes);
//     const permissionRoles = profile.attributes ? profile.attributes.role ?? [] : []
//     if(hasPermission(to.meta.roles, permissionRoles)) {
//       next();
//     } else {
//       //TODO:
//       //do next error message Unauthorized user.
//       next('/401')
//     }
//   } else {
//     const loginUrl = router.app.$keycloak.createLoginUrl({
//       redirectUri: to.path,
//     });
//     window.location.replace(loginUrl);
//   }
//   // next();
//   NProgress.done();
// });

// router.beforeEach(async (to, from, next) => {
//   NProgress.start();
//   let logMsg = '[router.beforeEach]';
//   try {
//     // determine if there has token
//     if (store.getters.token) {
//       logMsg += '\t[token]';
//       if (whiteList.includes(to.path)) {
//         logMsg += '\t[whiteList]';
//         next({ path: '/' });
//       } else {
//         logMsg += '\t[!whiteList]';
//         if (!store.getters.roles || store.getters.roles.length === 0) {
//           logMsg += `\t[roles=${store.getters.roles}]`;
//           // Determine whether the current user has pulled the user_info information
//           await store.dispatch('GetUserInfo');
//           if (!store.getters.user || !store.getters.user.roles) {
//             logMsg += '\t[LogOut]\t[next /]';
//             await store.dispatch('LogOut');
//             next({ path: '/' });
//           }

//           // note: roles must be a object array! such as:
//           // [{id: '1', name: 'editor'}, {id: '2', name: 'developer'}]
//           await store.dispatch('GenerateRoutes', store.getters.user);
//           if (!store.getters.permissionRoutes) {
//             logMsg += '\t[Redirect]\t[next /]';
//             next({ path: '/' });
//           }

//           // Hack method to ensure that addRoutes is complete,
//           // set the replace: true so the navigation will not leave a history record
//           next({ ...to, replace: true });
//         } else {
//           logMsg += `\t[roles=${store.getters.roles}]`;
//           // No need to dynamically change permissions can be directly next()
//           // delete the following permission judgment ↓
//           if (hasPermission(store.getters.roles, to.meta.roles)) {
//             logMsg += `\t[Permission=${to.meta.roles}]\t[next]`;
//             next();
//           } else {
//             logMsg += `\t[!Permission=${to.meta.roles}]\t[next /401]`;
//             next({ path: '/401', replace: true, query: { noGoBack: true } });
//           }
//         }
//       }
//     } else {
//       logMsg += '\t[!token]';
//       if (whiteList.includes(to.path)) {
//         logMsg += '\t[whiteList]';
//         next();
//       } else {
//         logMsg += '\t[!whiteList]';
//         next(`/signin?redirect=${to.path}`);
//       }
//     }
//   } catch (err) {
//     console.warn(`[router.beforeEach]\t${to.path}: ${err}`);
//   }
//   console.log(logMsg, to.path);
//   NProgress.done();
// });

router.afterEach(async () => {
  NProgress.done();
});

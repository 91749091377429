<template>
  <div class="v-application--wrap">
    <the-layout-drawer />
    <the-layout-toolbar />
    <v-main>
      <transition
        name="fade-transform"
        mode="out-in"
      >
        <keep-alive>
          <slot />
        </keep-alive>
      </transition>
    </v-main>
   <the-layout-fab/>
    <br/>
    <br/>
    <br/>
    <br/>
    <the-layout-to-top-fab  />
    <the-layout-footer/>


    <v-dialog v-model="dialogPassword" persistent max-width="600px">
        <v-card ref="formCard">
            <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeLoadingDialog" />
            <v-card-title>
                <span class="headline">Change password required for {{user}}</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry" v-model="isFormValid" lazy-validation>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="Password*" v-model="form.password" required :rules="[rules.requiredField]" :append-icon="passwordType.password ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (passwordType.password = !passwordType.password)" :type="passwordType.password ? 'password' : 'text'"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="New Password*" v-model="form.newPassword" required :rules="[rules.requiredField, rules.lengthRule, rules.digitRule, rules.smallRule, rules.bigRule, rules.specialRule]" :append-icon="passwordType.newPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (passwordType.newPassword = !passwordType.newPassword)" :type="passwordType.newPassword ? 'password' : 'text'"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Confirm Password*" v-model="form.confirmPassword" required :rules="[rules.requiredField, rules.matchPassword]" :append-icon="passwordType.confirmPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (passwordType.confirmPassword = !passwordType.confirmPassword)" :type="passwordType.confirmPassword ? 'password' : 'text'"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="closePassDialog">Log-out</v-btn>
                <v-btn color="blue darken-1" text v-on:click="savePassword" v-bind:disabled="!isFormValid">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialogWarning" persistent max-width="600px">
        <v-card ref="formCard">
            <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeLoadingDialog" />
        </v-card>
    </v-dialog>

  </div>
</template>

<script>
import TheLayoutDrawer from './TheLayoutDrawer.vue';
import TheLayoutToolbar from './TheLayoutToolbar.vue';
import TheLayoutFab from './TheLayoutFab.vue';
import TheLayoutToTopFab from './TheLayoutToTopFab.vue';
import TheLayoutFooter from './TheLayoutFooter.vue';
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import Loading from '@/views/components/Loading.vue'

export default {
  name: 'TheLayout',
  components: {
    TheLayoutDrawer,
    TheLayoutToolbar,
    TheLayoutFab,
    TheLayoutToTopFab,
    TheLayoutFooter,
    Loading,
  },
    data() {
        return {
            isDialogClose: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            closeLogOut: false,
            isHtml: false,
            dialogPassword: false,
            dialogWarning: false,
            form: {},
            passwordType: {
                password: String,
                newPassword: String,
                confirmPassword: String
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                matchPassword: v => v === this.form.newPassword || "Does not match new password",
                lengthRule: v => v.length > 7 || "Password too short",
                digitRule: v => /\d/.test(v) || "Password must contain number",
                smallRule: v => /[a-z]/.test(v) || "Password must contain a small letter",
                bigRule: v => /[A-Z]/.test(v) || "Password must contain a capital letter",
                specialRule: v => /[!@#\$%\^\&*\)\(+=._-]/.test(v) || "Password must contain at least one special character"
            },
            lastUpdate: "",
            isFormValid: false
        };
    },
    async mounted() {
        this.initializeForm(),
        setTimeout(async () => {
            console.log(this.user)
//            this.checkLastUpdate()
         }, 1000);
    },    
    computed: {
        ...mapGetters([
            'user',
            'attributes'
        ]),

    },
    methods: {
        closeDialog() {
            if (this.$refs.formEntry) {
                this.$refs.formEntry.reset()
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
            this.dialogProfile = false
            this.isDialogClose = true
            if(this.closeLogOut){
                this.closeLogOut = false
                this.logout();
            }
        },
        closeLoadingDialog(type) {
            if (type === 'loading') {
                this.loadingDialog = false
                this.closeDialog()
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
            this.dialogWarning = false
        },
        logout() {
            // this.$store.dispatch('LogOut');
            // this.$router.push('/signin');
            this.$keycloak.logoutFn({
                logoutRedirectUri: window.location.host,
            });
        },
        closePassDialog() {
            if (this.$refs.formEntry) {
                this.$refs.formEntry.reset()
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
            this.dialogPassword = false
            this.isDialogClose = true
            this.closeLogOut = false
            this.logout();
        },
        initializeForm() {
            this.form = {
                user: this.user,
                password: undefined,
                newPassword: undefined,
                confirmPassword: undefined
            }
        },
        async checkLastUpdate(){
            console.log("user: " + this.user)
            const createResponse = await API.getDatePasswordUpdate(this.user)
            if (!createResponse || createResponse.error) {
                // this.dialogPassword = true;
            } else {
              console.log("Date of last update: " + createResponse.message)
              this.lastUpdate = new Date(createResponse.message);

              const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
              const diffDays = Math.round(Math.abs((this.lastUpdate - new Date()) / oneDay));

              console.log("Number of days from last update: " + diffDays)
              //if regular accounts check 45 and 38 days else
              if(diffDays > 45){
                this.dialogPassword = true;
              } else if(diffDays > 37){
                  //check first login of the day? send email
                  this.showPassWarning();
              }
              //if admin accounts check 30 and 22 days
}
        },
        async savePassword() {
            this.$refs.formEntry.validate()
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            this.form.user = this.user
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            if(this.form.confirmPassword == null){
                this.loadingMessage = "Confirm password empty"
                this.loadingFinished = true
            } else
            if (this.form) {
                this.loadingMessage = `Updating password for <strong><i>${this.user}</i></strong>`
                setTimeout(async () => {
                    const createResponse = await API.updatePassword(this.form)
                    console.log(createResponse)
                    this.loadingFinished = true
                    if (!createResponse || createResponse.error) {
                        this.isHtml = false
                        if(createResponse.error.response.data.message) {
                            this.loadingMessage = createResponse.error.response.data.message
                        } else {
                            this.loadingMessage = createResponse.error
                        }
                    } else {
                        this.loadingMessage = `Successfully updated password for <strong><i>${this.user}</i>. Press OK to Logout.</strong>`
                        this.closeLogOut = true
                    }
                }, 2000);
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        async showPassWarning(){

            const loginDate = localStorage.getItem('LoginDate')
            console.log("loginDate: " + loginDate)

            if(loginDate < new Date() || !loginDate) {
                localStorage.setItem('LoginDate', new Date());
                this.loadingDialog = true
                this.isHtml = true
                this.loadingFinished = false
                this.form.user = this.user
                this.dialogWarning = true;

                this.loadingMessage = `Reminder: <strong><i>${this.user}</i></strong> your password is about to expire. Please change your password immediately.`
                this.loadingFinished = true
            }            
        }
      }
};


</script>


<style scoped>
.extra-space {
    margin-bottom:500px
}
</style>
<template>
<v-menu :close-on-content-click="isDialogClose" origin="center center" :nudge-bottom="10" transition="scale-transition">
    <template v-slot:activator="{on}">
        <v-btn icon large text v-on="on">
            <v-avatar size="36px">
                <img v-if="avatar" :src="avatar" alt="name">
                <v-icon v-else x-large :style="{ color: '#1a6398' }">
                    mdi-account-circle
                </v-icon>
            </v-avatar>
        </v-btn>
    </template>
    <v-list class="pa-0">
        <v-list>
            <v-list-item>
                <v-list-item-avatar>
                    <img v-if="avatar" :src="avatar" alt="name">
                    <v-icon v-else x-large class="blue--text">
                        mdi-account
                    </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>{{ name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ user }}</v-list-item-subtitle>
                </v-list-item-content>

                <!--<v-list-item-action>-->
                <!--<v-btn-->
                <!--:class="fav ? 'red&#45;&#45;text' : ''"-->
                <!--icon-->
                <!--@click="fav = !fav"-->
                <!--&gt;-->
                <!--<v-icon>mdi-heart</v-icon>-->
                <!--</v-btn>-->
                <!--</v-list-item-action>-->
            </v-list-item>
        </v-list>
        <v-divider />
        <v-dialog v-model="dialogProfile" max-width="500px" persistent>
            <v-card>
                <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeLoadingDialog" />
                <v-card-title class="headline indigo lighten-2">
                    <v-avatar class="mr-4" color="blue">
                        <v-icon dark>
                            mdi-account-circle
                        </v-icon>
                    </v-avatar>
                    <div class="text-xs-center">
                        <span>
                            <h2>USER PROFILE</h2>
                        </span>
                    </div>
                </v-card-title>

                <v-card-text class="mt-7"><b> USER: {{user}} </b></v-card-text>
                <v-card-text><b> NAME: {{name}} </b></v-card-text>
                <v-card-text><b> ROLE: {{whatRole()}}</b></v-card-text>

                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="primary" text @click="closeDialog">
                        Close
                    </v-btn>
                    <!-- <v-btn color="primary" text @click="displayPasswordForm">
                        Change Password

                        
                    </v-btn> -->
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPassword" persistent max-width="600px">
            <v-card ref="formCard">
                <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeLoadingDialog" />
                <v-card-title>
                    <span class="headline">Change Password for {{user}}</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="formEntry" v-model="isFormValid" lazy-validation>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <v-text-field label="Password*" v-model="form.password" required :rules="[rules.requiredField]" :append-icon="passwordType.password ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (passwordType.password = !passwordType.password)" :type="passwordType.password ? 'password' : 'text'"></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field label="New Password*" v-model="form.newPassword" required :rules="[rules.requiredField, rules.lengthRule, rules.digitRule, rules.smallRule, rules.bigRule, rules.specialRule]" :append-icon="passwordType.newPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (passwordType.newPassword = !passwordType.newPassword)" :type="passwordType.newPassword ? 'password' : 'text'"></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field label="Confirm Password*" v-model="form.confirmPassword" required :rules="[rules.requiredField, rules.matchPassword]" :append-icon="passwordType.confirmPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (passwordType.confirmPassword = !passwordType.confirmPassword)" :type="passwordType.confirmPassword ? 'password' : 'text'"></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                    <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text v-on:click="closePassDialog">Close</v-btn>
                    <v-btn color="blue darken-1" text v-on:click="savePassword" v-bind:disabled="!isFormValid">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-list-item :close-on-content-click="false" v-for="(item,index) in menuitems" :key="index" :to="!item.href ? {name: item.name} : null" :href="item.href" ripple="ripple" :disabled="item.disabled" :target="item.target" rel="noopener" @click="item.click">
            <v-list-item-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</v-menu>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import Loading from '@/views/components/Loading.vue'

export default {
    name: 'AppProfile',
    components: {
        Loading,
    },
    data() {
        return {
            dialogProfile: false,
            isDialogClose: true,
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            closeLogOut: false,
            isHtml: false,
            dialogPassword: false,
            menuitems: [{
                    icon: 'mdi-account',
                    href: '#',
                    title: 'toolbar.profile',
                    disabled: false,
                    click: () => {
                        this.showDialog();
                    },
                },
                {
                    icon: 'mdi-lock-reset',
                    href: '#',
                    title: 'toolbar.password',
                    disabled: false,
                    click: () => {
                        this.showPassDialog();
                    },
                },
                {
                    icon: 'mdi-settings',
                    href: '#',
                    title: 'toolbar.settings',
                    disabled: true,
                    click: () => {
                        console.log('this.toggleSettingsPanel()');
                        this.toggleSettingsPanel();
                    },
                },
                {
                    icon: 'mdi-exit-to-app',
                    href: '#',
                    title: 'toolbar.logout',
                    disabled: !this.$keycloak.authenticated,
                    click: () => {
                        this.logout();
                    },
                },
            ],
            form: {},
            passwordType: {
                password: String,
                newPassword: String,
                confirmPassword: String
            },
            rules: {
                requiredField: v => !!v || "Required field.",
                matchPassword: v => v === this.form.newPassword || "Does not match new password",
                lengthRule: v => v.length > 7 || "Password too short",
                digitRule: v => /\d/.test(v) || "Password must contain number",
                smallRule: v => /[a-z]/.test(v) || "Password must contain a small letter",
                bigRule: v => /[A-Z]/.test(v) || "Password must contain a capital letter",
                specialRule: v => /[!@#\$%\^\&*\)\(+=._-]/.test(v) || "Password must contain at least one special character"
            },
            isFormValid: false
        };
    },
    mounted() {
        this.initializeForm()
    },    
    computed: {
        ...mapGetters([
            'user',
            'name',
            'avatar',
            'status',
            'attributes',
            'role',
            'roles'
        ]),

    },
    methods: {
        whatRole() {
            // const userRoles = [] 
            // for (var i in this.roles) {
            //     var role = this.roles[i];
            //     userRoles.push(role.name);
            // }
            let userRoles = this.roles.map(role => role.name);

            return userRoles
        },
        showDialog() {
            this.dialogProfile = true
            this.isDialogClose = false
        },
        closeDialog() {
            if (this.$refs.formEntry) {
                this.$refs.formEntry.reset()
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
            this.dialogProfile = false
            this.isDialogClose = true
            if(this.closeLogOut){
                this.closeLogOut = false
                this.logout();
            }
        },
        closeLoadingDialog(type) {
            if (type === 'loading') {
                this.loadingDialog = false
                this.closeDialog()
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        displayPasswordForm() {
            console.log('pass')
            window.open(`${process.env.VUE_APP_KEYCLOAK_PASSWORD}`,'_blank');
        },
        logout() {
            // this.$store.dispatch('LogOut');
            // this.$router.push('/signin');
            this.$keycloak.logoutFn({
                logoutRedirectUri: window.location.host,
            });
        },
        toggleSettingsPanel() {
            console.log('this.toggleSettingsPanel()');
            this.$vuetify.goTo(0);
            this.$store.dispatch('SettingsPanelToggle');
        },
        showPassDialog() {
            this.dialogPassword = true
            this.isDialogClose = false
        },
        closePassDialog() {
            if (this.$refs.formEntry) {
                this.$refs.formEntry.reset()
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
            this.dialogPassword = false
            this.isDialogClose = true
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-password', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        initializeForm() {
            this.form = {
                user: this.user,
                password: undefined,
                newPassword: undefined,
                confirmPassword: undefined
            }
        },
        async savePassword() {
            this.$refs.formEntry.validate()
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            this.form.user = this.user
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            if(this.form.confirmPassword == null){
                this.loadingMessage = "Confirm password empty"
                this.loadingFinished = true
            } else
            if (this.form) {
                this.loadingMessage = `Updating password for <strong><i>${this.user}</i></strong>`
                setTimeout(async () => {
                    const createResponse = await API.updatePassword(this.form)
                    console.log(createResponse)
                    this.loadingFinished = true
                    if (!createResponse || createResponse.error) {
                        this.isHtml = false
                        if(createResponse.error.response.data.message) {
                            this.loadingMessage = createResponse.error.response.data.message
                        } else {
                            this.loadingMessage = createResponse.error
                        }
                    } else {
                        this.loadingMessage = `Successfully updated password for <strong><i>${this.user}</i>. Press OK to Logout.</strong>`
                        this.closeLogOut = true
                    }
                }, 2000);
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },


    },
};
</script>
